"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * Test if a value is a nodelist.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {any} value
 *
 * @returns {boolean}
 */
function default_1(value) {
    return NodeList.prototype.isPrototypeOf(value);
}
exports.default = default_1;
