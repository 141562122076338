import hasClass from './hasClass';
import selectAll from '../select/selectAll';
import each from '../../helpers/collection/each';
import isArray from '../../helpers/lang/isArray';

/*
 * Add class(es) to 1 or more nodes.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string|Array} nodes
 * @param {string|Array} className
 * @param {Node} context
 */
export default function (nodes, className, context) {

    // Add class(es) to chosen nodes
    each(selectAll(nodes, context), node => {
        each((isArray(className) ? className : (className || '').split(' ')), className => {
            if (node.classList) {
                node.classList.add(className);
            } else if (!hasClass(node, className)) {
                node.className += ` ${className}`;
            }
        });
    });
}
