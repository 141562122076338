"use strict";
/*
 * Parse an Object, very fast. Inspired by forEach()'s from lodash
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Object} object
 * @param {function|boolean} iteratee
 *
 * @returns {Object}
 */
Object.defineProperty(exports, "__esModule", { value: true });
var eachObject = function (obj, iteratee) {
    var iterable = Object(obj);
    var props = Object.keys(Object(obj));
    var length = props.length;
    var index = -1;
    while (length--) {
        var key = props[++index];
        if (iteratee(iterable[key], key, iterable) === false) {
            break;
        }
    }
    return obj;
};
exports.default = eachObject;
