import select from './select';
import isString from '../../helpers/lang/isString';

/*
 * Select matching Element from the DOM and return it.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|NodeList|string} node
 * @param {Node|string} context
 *
 * @returns {Node|null}
 */
export default function (node, context) {

    // Node provided
    if (node) {

        // Node is a string
        if (isString(node)) {
            return (select(context) || document).querySelector(node);
        }

        // Node is the document or window
        if (node === document || node === window) {
            return node;
        }

        // NodeList, get the first one only
        if (!node.tagName) {
            return node[0];
        }

        // Node is the document or window
        return node;
    }

    return null;
}
