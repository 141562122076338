import select from './select';
import each from '../../helpers/collection/each';
import isArray from '../../helpers/lang/isArray';
import isNodeList from '../../helpers/lang/isNodeList';
import isString from '../../helpers/lang/isString';

/*
 * Select all matching nodes from the DOM and return as an array.
 *
 * @author Christophe Meade
 * @copyright 2019-present Oceanway
 *
 * @param {Node|string|Array} nodes
 * @param {Node|string} context
 *
 * @returns {Array|null}
 */
export default function (nodes, context) {

    // Nodes provided
    if (nodes) {

        // Nodes is a string, select with querySelectorAll
        if (isString(nodes)) {

            // Parse NodeList & convert to an array
            const arrayNodes = [];
            each((select(context) || document).querySelectorAll(nodes), node => {
                arrayNodes.push(node);
            });
            nodes = arrayNodes;

        // Nodes is an array
        } else if (isArray(nodes)) {
            const arrayNodes = [];

            // Parse Nodes to select all elements
            each(nodes, node => {
                if (node) {

                    // Node is a String, select with querySelectorAll, parse & add to array
                    if (isString(node)) {
                        each((select(context) || document).querySelectorAll(node), selectNode => {
                            arrayNodes.push(selectNode);
                        });

                    // Node is a single DOM Element, add to array
                    } else if (node.tagName) {
                        arrayNodes.push(node);

                    // Node is an array of nodes
                    } else if (isArray(node) || isNodeList(node)) {
                        each(node, selectNode => {
                            arrayNodes.push(selectNode);
                        });
                    }
                }
            });
            nodes = arrayNodes;

        // Single DOM Element, make it an array
        } else if (nodes.tagName) {
            nodes = [nodes];

        // Nodes is the document or window
        } else if (nodes === document || nodes === window) {
            nodes = [nodes];
        }

        // Return the array of nodes
        return nodes;
    }

    return null;
}
